import React from "react";
import { Layout } from "../../components/Layout";
import SEO from "../../components/SEO";
import ContactForm from "../../components/ContactForm";
import { Hero } from "../../components/Hero";

const Contact = ({ data }) => (
  <Layout>
    <SEO
      title="Contact"
      description="Collection of photographs from my travels showing a mixture of the urban architecture and day-to-day adventures"
    />
    <div className="post-container">
      <Hero title="Contact" />
      <main className="post-content-container">
        <ContactForm />
      </main>
    </div>
  </Layout>
);

export default Contact;
