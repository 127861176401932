import React, { useState } from "react";

const ContactForm = () => {
  const submissionURL =
    "https://la7r1f6wk5.execute-api.us-east-1.amazonaws.com/production/";
  const [submitted, setSubmitted] = useState();
  const [validation, setValidation] = useState({
    hasValidated: false,
    nameValidated: false,
    emailValidated: false,
    messageValidated: false
  });

  const validateField = event => {
    setValidation({ ...validation, hasValidated: true });

    switch (event.target.name) {
      case "name":
        event.target.value != ""
          ? setValidation({ ...validation, nameValidated: true })
          : setValidation({ ...validation, nameValidated: false });
        break;
      case "email":
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(event.target.value)
          ? setValidation({ ...validation, emailValidated: true })
          : setValidation({ ...validation, emailValidated: false });
        break;
      case "message":
        event.target.value != ""
          ? setValidation({ ...validation, messageValidated: true })
          : setValidation({ ...validation, messageValidated: false });
        break;
      default:
        break;
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (!validation.hasValidated) {
      setValidation({ ...validation, hasValidated: true });
      return;
    } else if (
      !(
        !validation.nameValidated ||
        !validation.emailValidated ||
        !validation.messageValidated
      )
    ) {
      return;
    }

    document.getElementById("btnSubmit").value = "Sending...";

    try {
      fetch(submissionURL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(validation),
        headers: { "Content-type": "application/json; charset=UTF-8" }
      }).then(response => {
        if (!response.ok) {
          setSubmitted(false);
          throw new Error("An error has occured.");
        }

        setSubmitted(true);
      });
    } catch (error) {}
  };

  if (submitted == true) {
    return (
      <section className="post-content">
        <span style={{ fontSize: "1.5em" }}>
          Your message has been sent! Thank you.
        </span>
      </section>
    );
  } else if (submitted == false) {
    return (
      <section className="post-content">
        <span style={{ fontSize: "1.5em" }}>
          An error has occured. Your message has not been sent. Please try
          again.
        </span>
      </section>
    );
  } else {
    return (
      <section className="post-content">
        <form
          style={{ boxSizing: "border-box" }}
          method="post"
          onSubmit={handleSubmit}
        >
          {!validation.hasValidated ||
            (!validation.nameValidated && (
              <label htmlFor="name" style={{ color: "red", fontSize: "0.8em" }}>
                Name is required
              </label>
            ))}
          <input
            type="text"
            placeholder="Your Name"
            className="input-field"
            name="name"
            id="name"
            onBlur={validateField}
          ></input>
          {!validation.hasValidated ||
            (!validation.emailValidated && (
              <label htmlFor="name" style={{ color: "red", fontSize: "0.8em" }}>
                Email is required
              </label>
            ))}
          <input
            type="email"
            placeholder="Your Email"
            className="input-field"
            name="email"
            onBlur={validateField}
          ></input>
          {!validation.hasValidated ||
            (!validation.messageValidated && (
              <label htmlFor="name" style={{ color: "red", fontSize: "0.8em" }}>
                Message is required
              </label>
            ))}
          <textarea
            placeholder="Your Message"
            className="input-field"
            rows="10"
            name="message"
            onBlur={validateField}
          ></textarea>
          <input
            type="submit"
            value="Send"
            className="input-submit"
            id="btnSubmit"
          />
        </form>
      </section>
    );
  }
};

export default ContactForm;
